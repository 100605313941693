const redirectVisit = {
	id: 'redirectVisit',
	selectionType: 'single',
	label: 'actions.subscription.redirectVisit',
	functionality: 'READ_SUBSCRIPTION',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		let visit = btoa(`{ "id": ${row.visit} }`);

		this.$router.push('/visit/read/' + visit);
		row.isAction = true;
	}
};

export default {
	gridactions: [redirectVisit],
	formactions: []
};
